type Entity = {
    id: string,
    name: string,
    name_variants: string[] | null | undefined
    ticker?: string
    nct_id?: string
}

type ValueEntity = {
    value: string,
    name_variants: string[] | null | undefined
}


function variantFilterRelevance(entity: Entity, filter: string) {
    if(!filter || filter === "")
        return 1;
    if(!entity || !entity.name)
        return 0;

    if(entity.name === filter)
        return 10;
    if(entity.ticker && entity.ticker.toLowerCase() === filter.toLowerCase())
        return 9;
    if(entity.nct_id && entity.nct_id.toLowerCase() === filter.toLowerCase())
        return 8;
    if(entity.name_variants && entity.name_variants.includes(filter))
        return 7;
    if(entity.name.toLowerCase() === filter.toLowerCase())
        return 6;
    if(entity.ticker && entity.ticker.toLowerCase().startsWith(filter.toLowerCase()))
        return 5;
    if(entity.nct_id && (entity.nct_id.toLowerCase().startsWith(filter.toLowerCase()) || entity.nct_id.substring(3).startsWith(filter.replace('NCT', '')))) // can search by NCT or without NCT at the start of the filter
        return 4;
    if(entity.name.toLowerCase().startsWith(filter.toLowerCase()))
        return 3;
    if(entity.name.toLowerCase().includes(filter.toLowerCase()))
        return 2;
    if(entity.name_variants && entity.name_variants.some((variant) => variant.toLowerCase().includes(filter.toLowerCase())))
        return 1;
    return 0;
}

function valueVariantFilterRelevance(entity: ValueEntity, filter: string) {
    if(!filter || filter === "")
        return 1;
    if(!entity)
        return 0;
    if(entity.value === filter)
        return 10;
    if(entity.name_variants && entity.name_variants.includes(filter))
        return 7;
    if(entity.value.toLowerCase().includes(filter.toLowerCase()))
        return 2;
    if(entity.name_variants && entity.name_variants.some((variant) => variant.toLowerCase().includes(filter.toLowerCase())))
        return 1;
    return 0;
}

export { valueVariantFilterRelevance };
export default variantFilterRelevance;


