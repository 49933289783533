/*
When the user clicks new chat, we don't want any latency.
To avoid back-and-forth in this case, the client will just create a new empty chat server-side and then update the session when a meaningful action is recieved.
*/    

import getUUID from "@/utils/getUUID";
import store from "@/redux/store";
import getSocket from "@/globals/socketConfig";
import { setSessionData } from "@/redux/sessionSlice";
import { setActiveActionIndex, setActiveChatIndex, setMaxActionIndex } from "@/redux/tabSlice";
import { resetFocusFilters } from "@/components/Layout/Focus";

function newChat() 
{
	console.log('Trying to create new chat');
	const state = store.getState();
	const session = state.session.sessionData;
	const socket = getSocket();

	if(!session)
		return;

	let newSessionData = {...session};

	const newChatId = getUUID();
	//console.log(newChatId);

	//Unfortunately, can't use the chat constructor, since redux requires a serializable object.
	const newChatObject = {
		action_type: 'new_chat',
		session_id: session.id,
		id: newChatId,
		chat_id: newChatId,
		created_at: Date.now().toString(),
		chat_title: 'New Chat',
		actions: [],
	}

	let newChats = [newChatObject, ...newSessionData.chats] as Array<Object>;

	console.log('Creating new chat', newChatObject);

	socket.emit('take_action', newChatObject);

	newSessionData.chats = newChats as any;
	console.log('Updated session data: ', newSessionData);

	store.dispatch(setSessionData(newSessionData));

	//Set active chat to the new chat
	store.dispatch(setActiveChatIndex(0));
	
	resetFocusFilters();

	return newChatObject;
}

export default newChat;