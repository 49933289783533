import { useState } from "react";
import ContentCopy from "@material-symbols/svg-400/outlined/content_copy.svg"
import Check from "@material-symbols/svg-400/outlined/check.svg"
import { cn } from "@/utils/lib/utils";
import { markdownToComponent } from "@/utils/convertMarkdown";
import ReactDOMServer from 'react-dom/server';

interface CopyTextProps {
	text: string;
	className?: string;
	richCopy?: boolean;
	spanProps?: React.HTMLProps<HTMLSpanElement>;
}

const CopyText = ({text, className, spanProps, richCopy}: CopyTextProps) => 
{
	const [copied, setCopied] = useState(false);

	function copyToClipboard() {

		if(richCopy)
		{
			//Convert markdown first
			const component = markdownToComponent(text);
			const htmlText = ReactDOMServer.renderToStaticMarkup(component);

			navigator.clipboard.write([
				new ClipboardItem({
				  'text/html': new Blob([htmlText], { type: 'text/html' }),
				  'text/plain': new Blob([text], { type: 'text/plain' })
				})
			])
		}	
		else
		{
			navigator.clipboard.writeText(text);
		}
	}

	return (
    <span
    onClick={(e) => { copyToClipboard(); setCopied(true); setTimeout(() => setCopied(false), 2000); e.stopPropagation()}} 
    className={cn('text-icon text-neutral-400 min-w-4 min-h-4 opacity-0 group-hover:opacity-100 transition-all cursor-pointer hover:text-neutral-700', className)}
	{...spanProps}
	>
      {copied ? <Check/> : <ContentCopy/>}
    </span>
	)
}

export default CopyText;