'use client';

import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import EnumDropdown from './EnumDropdown';
import FilterableEnumDropdown, { VariantsFilterableEnumDropdown } from './FilterableEnumDropdown';

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';

import { Button } from '@/components/ui/button';
import formatName from '@/utils/format/formatName';

import CheckboxOutlineBlank from '@material-symbols/svg-400/outlined/check_box_outline_blank.svg';
import Checkbox from '@material-symbols/svg-400/outlined/check_box.svg';
import CheckboxDisallowed from '@material-symbols/svg-400/outlined/disabled_by_default.svg';
import Cancel from '@material-symbols/svg-400/outlined/cancel.svg';
import ExpandMore from '@material-symbols/svg-400/outlined/keyboard_arrow_down.svg';
import Search from '@material-symbols/svg-400/outlined/search.svg';
import Keyboard from '@material-symbols/svg-400/outlined/keyboard.svg';
import Circle from '@material-symbols/svg-400/outlined/circle-fill.svg';
import Info from '@material-symbols/svg-400/outlined/info.svg';
import Lock from '@material-symbols/svg-400/outlined/lock.svg';
import FilterListOff from '@material-symbols/svg-400/outlined/filter_list_off.svg';
import Cycle from '@material-symbols/svg-400/outlined/cycle.svg';

import useMatchWidth from '@/utils/hooks/useMatchWidth';
import BitSet from 'bitset';

import { FixedSizeList as List } from 'react-window';
import variantFilterRelevance from '@/utils/filtering/variantFilterRelevance';
import { quickestFilterRelevance } from '@/utils/filtering/filterRelevance';
import { markdownToComponent } from '@/utils/convertMarkdown';
import highlightMatches, { quickHighlightMatches } from '@/utils/filtering/highlightMatches';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { Portal } from '@radix-ui/react-tooltip';
import reverseFormat from '@/utils/format/reverseFormat';
import useStatefulBitset from '@/utils/hooks/useStatefulBitset';
import { cn } from '@/utils/lib/utils';
import { z_classes } from '@/utils/z';

interface AdaptiveEnumDropdownOptions
{
	//Whether the dropdown should allow multiple selections
	selectionStyle: 'noretain' | 'single' | 'multiselect';

	//The name of the value to display in the dropdown trigger.
	//If value name is provided - the format will be '{count} {options.valueName} selected' or 'Select a {options.valueName}' if no values are selected.
	valueName?: string;
	//If the value name override is provided, it will be override the entire display string.
	valueNameOverride?: string;

	//Whether the dropdown should stay open after a selection is made
	stayOpen?: boolean;

	//Whether the filter should be formatted with the formatName utility
	formattedFilter?: boolean;

	//Whether the dropdown should disallow deselection of selected values
	no_deselect?: boolean;

	//Limit the number of values selected
	limit?: number;

	//If at the limit, does clicking on a new one deselect the first one?
	replaceAtLimit?: boolean;

	//Allow content management to be handled by the parent, rather than the component
	innerOnly?: boolean;

	//Height of the list
	listHeight?: number;

	//Separated description onto second line
	separatedDescriptions?: boolean;

	//Search field placeholder
	searchPlaceholder?: string;
}

interface AdaptiveEnumDropdownProps
{
	//The enum values to display in the dropdown
	enumValues?: any[];

	// Providing the values directly is an alternative to allowing this component to manage the selection.
	//This results in worse absolute performance, but is useful when the enum values and selections might change without the component being unmounted.
	value?: string | string[];

	//Standard value setter
	setValue: any; //((value: string) => void) | ((value: string[]) => void); //The value setter is either a single value setter or a multi-value setter, but omitting the type annotation because it can take a second-order function accepting previous state

	//If the calling context needs the full object, pass it. This is for things like companies with name variants - this will pass the full company, not just the name
	setFull?: (value: any) => void;

	//If this component is managing the selection, we can provide the initial selection indices here.
	initialSelectionIndices?: number[];
	valuesForInitialSelection?: string[];

	options?: Partial<AdaptiveEnumDropdownOptions>;

	//Props to pass to the trigger button
	triggerProps?: any;

	//Props to pass to the dropdown content
	contentProps?: any;

	//Props to pass to the inner text
	innerTextClassName?: string;
}

const defaultOptions: AdaptiveEnumDropdownOptions = {
	valueName: 'value',
	selectionStyle: 'single'
};

const AdaptiveEnumDropdown = ({
	enumValues,
	value,
	setValue,
	setFull,
	options = {},
	initialSelectionIndices,
	valuesForInitialSelection,
	triggerProps,
	contentProps,
	innerTextClassName
}: AdaptiveEnumDropdownProps) =>
{
	options = { ...defaultOptions, ...options };

	//The type determines how the initial indices are handled, so calculate it first.
	const type = useMemo(() =>
	{
		if (!enumValues || enumValues.length === 0) return 'string';

		if (enumValues.some(value => value.key))
		{
			return 'key';
		}
		if (enumValues.some(value => value.name_variants))
		{
			return 'name_variants';
		}
		else if (enumValues.some(value => value.name && !value.section_header))
		{
			return 'named_object';
		}

		return 'string';
	}, [enumValues]);

	//Before initializing selection, determine the initial indices
	const initialIndices = useMemo(() =>
	{
		if (initialSelectionIndices) return initialSelectionIndices;
		if (valuesForInitialSelection)
		{
			let indices: number[] = [];
			enumValues?.forEach((value, index) =>
			{
				if (type === 'key')
				{
					if (valuesForInitialSelection.includes(value.key))
					{
						indices.push(index);
					}
				}
				else if (type === 'name_variants' || type === 'named_object')
				{
					if (valuesForInitialSelection.includes(value.name))
					{
						indices.push(index);
					}
				}
				else if (valuesForInitialSelection.includes(value))
				{
					indices.push(index);
				}
			});
			return indices;
		}
		return [];
	}, [initialSelectionIndices, valuesForInitialSelection, enumValues, type]);

	//useEffect handlers are connected to the selection and enum values, but they should only run after actual changes, not on initial mount
	const initialSelectHandled = useRef(false);
	const initialEnumValuesHandled = useRef(false);

	/*
	This component needs to handle lists of indefinite length, so it uses a BitSet to store the selected indices when performance would suffer from using an array.
	*/
	const selectionDriver = useMemo(() =>
	{
		if (value !== undefined) return 'value';

		//If performance is a concern, use a bitset for large enum values
		if (enumValues?.length && enumValues.length > 10000) return 'bitset';

		return 'indices';
	}, []);
	const { bitsetSelection, addBitsetSelection, removeBitsetSelection, setBitsetSelection } = useStatefulBitset();
	const [selectedIndicesState, setSelectedIndicesState] = useState(initialIndices);

	const selectedIndices: number[] = useMemo(() =>
	{
		if (!enumValues || enumValues.length === 0) return [];

		if (options.selectionStyle === 'noretain') return [];

		switch (selectionDriver)
		{
			case 'value':
				//console.log('Extracting selected indices from value', value, enumValues);
				if (typeof value === 'string')
				{
					let index = -1;
					if (type === 'key')
					{
						index = enumValues.findIndex(val => val.key === value);
					}
					else if (type === 'name_variants' || type === 'named_object') index = enumValues.findIndex(val => val.name === value);
					else index = enumValues.findIndex(val => val === value);

					if (index === -1) return [];

					return [index];
				}
				else if (Array.isArray(value))
				{
					return value.map(val =>
					{
						let index = -1;
						if (type === 'key') index = enumValues.findIndex(v => v.key === val);
						if (type === 'name_variants' || type === 'named_object') index = enumValues.findIndex(v => v.name === val);
						else index = enumValues.findIndex(v => v === val);

						return index;
					});
				}
				return [];
			case 'bitset':
				return bitsetSelection.toArray();
			default:
				return selectedIndicesState;
		}
	}, [value, selectedIndicesState, bitsetSelection]);

	const setSelectedIndices = (indices: number[]) =>
	{
		if (!enumValues || enumValues.length === 0) return;

		setSelectedIndicesState(indices);

		if (selectionDriver === 'bitset') setBitsetSelection(new BitSet(indices)); // optimize this to not create a new BitSet and use updater hook if needed

		//When the selection is changed, send to parent
		let values: string[] = [];
		if (indices.length === 0 || (indices.length === 1 && indices[0] === -1))
		{
			if (options.selectionStyle === 'multiselect')
			{
				(setValue as (value: string[]) => void)([]);
			}
			else
			{
				(setValue as (value: string) => void)('');
			}
			return;
		}

		if (type === 'name_variants' || type === 'named_object')
		{
			values = indices.map(index =>
			{
				return enumValues[index].name;
			});
		}
		else
		{
			values = indices.map(index =>
			{
				return enumValues[index];
			});
		}

		if (options.selectionStyle === 'multiselect')
		{
			(setValue as (value: string[]) => void)(values);
		}
		else
		{
			(setValue as (value: string) => void)(values[0]);
		}

		//If the calling context needs the full object, pass it
		if (setFull)
		{
			if (options.selectionStyle === 'multiselect')
			{
				let fullValues = indices.map(index =>
				{
					return enumValues[index];
				});
				setFull(fullValues);
			}
			else
			{
				setFull(enumValues[indices[0]]);
			}
		}

		//If no-retain is selected, after a selection is made, clear the selection
		if (options.selectionStyle === 'noretain' && selectedIndices.length > 0)
		{
			setSelectedIndices([]);
		}
	};

	const [open, setOpen] = useState(false);
	const [filter, setFilter] = useState('');
	const { triggerRef, contentRef, setWidth } = useMatchWidth();
	const filterRef = useRef<HTMLInputElement>(null);
	const listRef = useRef<any>(null);

	//What text to display in the dropdown trigger
	const displayValue = useMemo(() =>
	{
		if (
			!enumValues ||
			options.selectionStyle === 'noretain' ||
			selectedIndices.length === 0 ||
			(selectedIndices.length === 1 && selectedIndices[0] === -1)
		)
		{
			if (options.valueNameOverride) return options.valueNameOverride;

			return `Select a${
				options.valueName && ['a', 'e', 'i', 'o', 'u'].includes(options.valueName.charAt(0).toLowerCase()) ? 'n' : ''
			} ${options.valueName ?? 'value'} ${options.limit ? `(Max of ${options.limit})` : ''}`;
		}
		if (selectedIndices.length === 1)
		{
			const item = enumValues[selectedIndices[0]];
			return formatName(item?.name ?? item);
		}

		return `${selectedIndices.length} selected`;
	}, [enumValues, selectedIndices, options.valueName]);

	//If there are more than 25 values, show the filter
	const showFilter = useMemo(() =>
	{
		return true;
		//return enumValues && enumValues.length > 25;
	}, [enumValues]);

	//Filter the values based on the filter text, and the type of enum
	const filteredValueIndices = useMemo(() =>
	{
		if (!enumValues || enumValues.length === 0) return [];

		let indices: number[] = [];

		if (!showFilter || !filter || filter === '')
		{
			indices = Array.from(Array(enumValues.length).keys());
			return indices;
		}
		else
		{
			let filteredValuesSet = new BitSet();
			enumValues.forEach((value, index) =>
			{
				if (type === 'name_variants' || type === 'key')
				{
					if (variantFilterRelevance(value, filter) > 0) filteredValuesSet.set(index);
					else if (options.formattedFilter && variantFilterRelevance(value, reverseFormat(filter))) filteredValuesSet.set(index);
				}
				else if (type === 'named_object')
				{
					if (quickestFilterRelevance(value.name, filter) > 0) filteredValuesSet.set(index);
					else if (options.formattedFilter && quickestFilterRelevance(value.name, reverseFormat(filter)) > 0) filteredValuesSet.set(index);
				}
				else
				{
					if (quickestFilterRelevance(value, filter) > 0) filteredValuesSet.set(index);
					else if (options.formattedFilter && quickestFilterRelevance(value, reverseFormat(filter)) > 0) filteredValuesSet.set(index);
				}
			});

			indices = filteredValuesSet.toArray();
		}

		//Put all the selected values at the top
		let notSelected = [...indices.filter(index => !selectedIndices.includes(index))];

		//If using name variants, sort by relevance
		if (type === 'name_variants')
		{
			//This has the potential to be slow, but this
			notSelected = notSelected.sort((a, b) =>
			{
				return variantFilterRelevance(enumValues[b], filter) - variantFilterRelevance(enumValues[a], filter);
			});
		}

		indices = [...selectedIndices, ...notSelected];

		const filtered = enumValues.filter((value, index) => indices.includes(index));
		console.log('Filtered', filtered, 'from', enumValues, 'with', filter);

		return indices;
	}, [enumValues, selectedIndices, filter]);

	//When the enum values change, reset the selected indices
	useEffect(() =>
	{
		if (selectionDriver === 'value')
			//No need to reset the selected indices if the value is being managed by the parent
			return;

		if (!initialEnumValuesHandled.current)
		{
			initialEnumValuesHandled.current = true;
			return;
		}

		if (initialIndices.length === 0) return;

		//deep equality check on array
		if (initialIndices.length === selectedIndices.length && initialIndices.every((value, index) => value === selectedIndices[index])) return;

		setSelectedIndices(initialIndices);
	}, [enumValues]);

	//When the filter changes, focus the filter input. This is to resolve a quirk of the dropdown menu interacting with the input because Radix is really good :)))))
	useEffect(() =>
	{
		//When the dropdown is opened or filter changes, reset the focus index
		focusIndex.current = 0;

		//console.log("focusing filter");
		filterRef.current?.focus();
		setTimeout(() =>
		{
			filterRef.current?.focus();
		}, 100);
	}, [filter, open]);

	//When the selected indices change, update the value in the parent component
	useEffect(() =>
	{
		//Only change the selected indices if the initial selection has been handled
		if (!initialSelectHandled.current)
		{
			initialSelectHandled.current = true;
			return;
		}

		if (!enumValues || enumValues.length === 0) return;
	}, [selectedIndices]);

	//Allow arrow key navigation of the dropdown
	const focusIndex = useRef(0);
	useEffect(() =>
	{
		if (!open) return;

		const handleKeyDown = (e: KeyboardEvent) =>
		{
			if (e.key === '\\')
			{
				filterRef.current?.focus();
				setTimeout(() =>
				{
					filterRef.current?.focus();
				}, 100);
				return;
			}

			if (e.key === 'Escape')
			{
				setOpen(false);
				return;
			}
			else if (e.key === 'ArrowDown')
			{
				focusIndex.current = Math.min(focusIndex.current + 1, filteredValueIndices.length - 1);
			}
			else if (e.key === 'ArrowUp')
			{
				focusIndex.current = Math.max(focusIndex.current - 1, 0);
			}

			const element = document.getElementById(`adaptive-dropdown-item-${focusIndex.current}`);

			if (e.key === 'ArrowDown' || e.key === 'ArrowUp')
			{
				element?.focus();
				element?.scrollIntoView({ block: 'center' });
				e.preventDefault();
				e.stopPropagation();
			}

			if (e.key === 'Enter')
			{
				if (focusIndex.current < 0) return;
				element?.click();
				setTimeout(() =>
				{
					//If this element is already selected, then clicking will deselect so there's no need to move the focus
					if (selectedIndices.includes(filteredValueIndices[focusIndex.current]))
					{
					}
					else
					{
						focusIndex.current++;
					}

					const nextElement = document.getElementById(`adaptive-dropdown-item-${focusIndex.current}`);
					nextElement?.focus();
				}, 100);
			}

			//console.log('Focus index', focusIndex.current, element);
		};

		document.addEventListener('keydown', handleKeyDown);
		return () =>
		{
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [open, selectedIndices]);

	const bufferTimerRef = useRef<any>(null);
	const bufferFilterInput = useMemo(() =>
	{
		return enumValues && enumValues?.length > 1000;
	}, [enumValues]);

	const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		console.log('Filter change', e.target.value, bufferFilterInput);

		const applyFilter = () =>
		{
			console.log('Applying filter', e.target.value);
			let raw = e.target.value;

			//Filter is converted into regex, so trim special characters
			let formattedFilter = raw.replace(/[\/\\^$*+?.()|[\]{}]/g, '');

			setFilter(formattedFilter);
			e.preventDefault();
			e.stopPropagation();
		};

		if (e.target.value.length === 0)
		{
			applyFilter();
			return;
		}

		if (bufferFilterInput)
		{
			if (bufferTimerRef.current)
			{
				clearTimeout(bufferTimerRef.current);
			}

			bufferTimerRef.current = setTimeout(() =>
			{
				applyFilter();
			}, 500);
		}
		else
		{
			applyFilter();
		}
	};

	//We use react-window to render the list of values, so we need to define a row component that can be virtualized.
	const Row = ({ index, style }: { index: number; style: any }) =>
	{
		let flatIndex = filteredValueIndices[index];
		const item = enumValues ? enumValues[flatIndex] : '';
		let display: string | JSX.Element;

		if (item && item.section_header)
		{
			return (
				<div style={style} className='p-1 pt-3'>
					<p className='text-neutral-400 text-sm'>{item.name}</p>
					<DropdownMenuSeparator />
				</div>
			);
		}

		const selected = selectedIndices.includes(flatIndex);

		if (typeof item === 'string')
		{
			display = formatName(item);
		}
		else
		{
			if (!item || (item.name === null && item.name_variants === null))
			{
				console.log('Missing item name or name variants', item, typeof item);
				display = 'No value available';
			}
			else if (item.name === null && item.name_variants !== null && item.name_variants.length > 0)
			{
				display = item.name_variants[0] as string;
			}
			else
			{
				display = formatName(item.name);
			}
		}

		let targetMatch = '';
		if ((type === 'key' || type === 'name_variants') && quickestFilterRelevance(item.name, filter) === 0)
		{
			targetMatch =
				item?.name_variants?.find((variant: string) => quickestFilterRelevance(variant, filter) > 0) ??
				(item.ticker && quickestFilterRelevance(item.ticker, filter) > 0 ? item.ticker : '');
		}
		if (targetMatch.length > 0)
		{
			//Clean target match
			targetMatch = targetMatch.replace(/\n/gi, ' ').replace(/\s+/gi, ' ').trim();
			display += ` (${targetMatch})`;
			//console.log(`Matched ${targetMatch} in ${item.name}`);
		}

		if (filter && filter.length > 0) display = markdownToComponent(quickHighlightMatches(display, filter));

		if (typeof display !== 'string' && !(display as JSX.Element).type)
		{
			console.log('Invalid display', display);
			display = '< ... >';
		}

		const lock = options.limit && selectedIndices.length >= options.limit && !selected;

		//Description on second line requires change on formatting
		if (options.separatedDescriptions)
		{
			return (
				<button
					className={cn(
						'flex flex-row hover:bg-neutral-100 active:bg-neutral-200 cursor-pointer p-2 pr-0 text-nowrap',
						lock && !options.replaceAtLimit ? 'text-neutral-600 bg-neutral-300 pointer-events-none' : '',
						lock && options.replaceAtLimit ? 'text-neutral-600 bg-neutral-300' : ''
					)}
					style={style}
					id={`adaptive-dropdown-item-${index}`}
					role='menuitemradio'
					onClick={e =>
					{
						if (options.selectionStyle === 'multiselect')
						{
							if (selected)
							{
								setSelectedIndices(selectedIndices.filter(value => value !== flatIndex));
							}
							else
							{
								const newIndices = [...selectedIndices, flatIndex];

								if (options.limit && newIndices.length > options.limit && options.replaceAtLimit)
								{
									newIndices.shift();
								}

								setSelectedIndices(newIndices);
							}
						}
						else
						{
							if (selected && !options.no_deselect)
							{
								setSelectedIndices([]);
							}
							else
							{
								setSelectedIndices([flatIndex]);
							}
						}

						if (!options.stayOpen) setOpen(false);
					}}
				>
					<div className='p-2'>
						{lock ? (
							<span className='min-w-4 text-[16px]'>
								<CheckboxDisallowed />
							</span>
						) : (
							<span className={'text-icon-sm' + (selected ? '  min-w-4' : '')}>
								{options.selectionStyle === 'multiselect' ? selected ? <Checkbox /> : <CheckboxOutlineBlank /> : selected ? '•\t' : ''}
							</span>
						)}
					</div>
					<div className='flex flex-col'>
						<div className='flex flex-row items-center gap-2'>
							{display}
							{item?.flags && item.flags.map((flag: string) => <span className='text-xs text-neutral-500 p-1 bg-neutral-50 rounded-lg px-2'>{flag}</span>)}
						</div>
						<p className='text-nowrap text-sm text-neutral-500'>{item.description ?? 'No description available'}</p>
					</div>
				</button>
			);
		}
		else
		{
			return (
				<button
					className={cn(
						'flex flex-row items-center gap-x-2 hover:bg-neutral-100 active:bg-neutral-200 cursor-pointer p-2 pr-0 text-nowrap',
						lock && !options.replaceAtLimit ? 'text-neutral-600 bg-neutral-300 pointer-events-none' : '',
						lock && options.replaceAtLimit ? 'text-neutral-600 bg-neutral-300' : ''
					)}
					style={style}
					id={`adaptive-dropdown-item-${index}`}
					role='menuitemradio'
					onClick={e =>
					{
						if (options.selectionStyle === 'multiselect')
						{
							if (selected)
							{
								setSelectedIndices(selectedIndices.filter(value => value !== flatIndex));
							}
							else
							{
								const newIndices = [...selectedIndices, flatIndex];

								if (options.limit && newIndices.length > options.limit && options.replaceAtLimit)
								{
									newIndices.shift();
								}

								setSelectedIndices(newIndices);
							}
						}
						else
						{
							if (selected && !options.no_deselect)
							{
								setSelectedIndices([]);
							}
							else
							{
								setSelectedIndices([flatIndex]);
							}
						}

						if (!options.stayOpen) setOpen(false);
					}}
				>
					{lock ? (
						<span className='min-w-4 text-[16px]'>
							<CheckboxDisallowed />
						</span>
					) : (
						<span className={'text-[16px]' + (selected ? '  min-w-4' : '')}>
							{options.selectionStyle === 'multiselect' ? selected ? <Checkbox /> : <CheckboxOutlineBlank /> : selected ? '•\t' : ''}
						</span>
					)}

					{display}
					{item?.description && (
						<Tooltip delayDuration={0}>
							<TooltipTrigger>
								<div className='px-0.5 text-left justify-start text-base'>
									<Info className='text-[16px]' />
								</div>
							</TooltipTrigger>
							<Portal>
								<TooltipContent className={cn('flex flex-col bg-white p-4 max-w-[45ch]', z_classes.overlay[4])} side='right'>
									{item.description ?? 'No description available'}
								</TooltipContent>
							</Portal>
						</Tooltip>
					)}
				</button>
			);
		}
	};

	const inner = (
		<Fragment>
			<div className='flex flex-col w-full'>
				{showFilter && (
					<input ref={filterRef} className={options.innerOnly ? 'border rounded-md border-neutral-200 ring-0 focus:ring-0 w-full' : 'border-0 focus:border-0 ring-0 focus:ring-0 w-full'} placeholder={options.searchPlaceholder ?? 'Search'} onChange={onFilterChange} />
				)}
				{options.selectionStyle === 'multiselect' && (
					<div className={cn('flex flex-row px-3 gap-x-4 text-xs text-neutral-500 text-nowrap ', (options.innerOnly ? 'pt-2' : ''))}>
						{!options.limit ? (
							<p
								className='cursor-pointer hover:text-maven-primary-400 transition-all'
								onClick={() =>
									{
									setSelectedIndices(Array.from(Array(enumValues?.length).keys()));
								}}
							>
								Select All
							</p>
						) : (
							<p>
								Max of {options.limit} selection{options.limit > 1 ? 's' : ''}
							</p>
						)}
						<p
							className='cursor-pointer hover:text-maven-primary-400 transition-all'
							onClick={() =>
							{
								setSelectedIndices([]);
							}}
						>
							Clear Selection
						</p>
					</div>
				)}
				<DropdownMenuSeparator className='first:hidden' />
			</div>

			{!enumValues || enumValues.length === 0 ? (
				<div className='p-2'>
					<div className='flex flex-col w-full h-full items-center justify-center border-dashed border-2 p-4'>
						<p className='text-icon-xl text-neutral-400'>
							<Cycle className='animate-spin' />
						</p>
						<p className='text-base text-neutral-500'>Loading {options.valueName} list...</p>
					</div>
				</div>
			) : (
				filteredValueIndices.length === 0 && (
					<div className='p-2'>
						<div className='flex flex-col w-full h-full items-center justify-center border-dashed border-2 p-4'>
							<p className='text-icon-xl text-neutral-400'>
								<FilterListOff />
							</p>
							<p className='text-base text-neutral-500'>No results</p>
						</div>
					</div>
				)
			)}

			<List
				height={
					options.listHeight ??
					Math.min(
						filteredValueIndices.length * 35 +
							(listRef.current && listRef.current.parentElement?.scrollWidth > listRef.current.parentElement?.clientWidth ? 20 : 0),
						300
					)
				}
				itemCount={filteredValueIndices.length}
				itemSize={options.separatedDescriptions ? 60 : 35}
				width={'100%'}
				innerRef={listRef}
			>
				{Row}
			</List>
		</Fragment>
	);

	if (options.innerOnly) return inner;

	return (
		<DropdownMenu open={open} onOpenChange={setOpen}>
			<DropdownMenuTrigger asChild>
				<Button ref={triggerRef} variant='outline' className='flex flex-row justify-between w-full border-neutral-200 py-[20px]' {...triggerProps}>
					<p className={cn('overflow-hidden text-nowrap text-ellipsis max-w-full', innerTextClassName)}>{displayValue}</p>
					<span className='text-icon'>
						<ExpandMore />
					</span>
				</Button>
			</DropdownMenuTrigger>

			<DropdownMenuContent
				ref={contentRef}
				align='start'
				{...contentProps}
				className={cn('flex flex-col max-h-[40dvh] overflow-x-hidden overflow-y-auto', contentProps?.className ?? '')}
			>
				{inner}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default AdaptiveEnumDropdown;
