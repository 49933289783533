import { cn } from "@/utils/lib/utils"
import { KeybindTooltip } from "../Help/KeybindManager"

interface NavIconProps {
	iconComponent: any
	bind?: string
	className?: string
	id?: string
	tag?: 'button' | 'div'
	onClick?: () => void
}

const NavIcon = ({iconComponent, bind, className, id, onClick, tag='button'}: NavIconProps) => {

	const inner = (
		tag === 'div' ?
		<div id={id} onClick={onClick} className={cn("flex items-center justify-center text-icon-lg text-neutral-500 hover:text-neutral-900", (bind ? 'cursor-pointer' : ''), className)}>
			{iconComponent}
		</div>
		:
		<button id={id} onClick={onClick} className={cn("flex items-center justify-center text-icon-lg text-neutral-500 hover:text-neutral-900", (bind ? 'cursor-pointer' : ''), className)}>
			{iconComponent}
		</button>
	)

	if(bind)
	{
		return (
		<KeybindTooltip bind={bind}>
			{inner}
		</KeybindTooltip>
		)
	}

	return (
		inner
	)
}

export default NavIcon;