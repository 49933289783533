import store from '@/redux/store';
import { 
	setMountTime as setMountTimeRedux, 
	setStartConnectionTime as setStartConnectionTimeRedux, 
	setConnectionTime as setConnectionTimeRedux, 
	setSessionsFetchTime as setSessionsFetchTimeRedux, 
	setSessionRetrievalTime as setSessionRetrievalTimeRedux, 
	setTabRetrievalTime as setTabRetrievalTimeRedux, 
	setActualRenderTime as setActualRenderTimeRedux 
} from '../redux/performanceSlice';

const logPerformance = true;

const setMountTime = (time: number) =>
{	


	store.dispatch(setMountTimeRedux(time));
	//console.log('[Performance] Mount time: ' + time + 'ms');
};

const setStartConnectionTime = (time: number) =>
{
	const state = store.getState().performance;
	store.dispatch(setStartConnectionTimeRedux(time));
	//console.log('[Performance] Component mount to connection time: ' + (time - (state.mountTime ?? 0)) + 'ms');
};

const setConnectionTime = (time: number) =>
{
	const state = store.getState().performance;
	store.dispatch(setConnectionTimeRedux(time));
	//console.log('[Performance] Connection time: ' + (time - (state.startConnectionTime ?? 0)) + 'ms');
};

const setSessionsFetchTime = (time: number) =>
{
	const state = store.getState().performance;
	if (state.sessionsFetchTime !== null)
		return;

	store.dispatch(setSessionsFetchTimeRedux(time));
	//console.log('[Performance] Sessions fetch time: ' + (time - (state.connectionTime ?? 0)) + 'ms');
};

const setSessionRetrievalTime = (time: number) =>
{
	const state = store.getState().performance;
	store.dispatch(setSessionRetrievalTimeRedux(time));
	//console.log('[Performance] Session retrieval time: ' + (time - (state.sessionsFetchTime ?? 0)) + 'ms');
};

const setTabRetrievalTime = (time: number) =>
{
	const state = store.getState().performance;
	if (state.tabRetrievalTime !== null)
		return;

	store.dispatch(setTabRetrievalTimeRedux(time));
	//console.log('[Performance] Tab retrieval time: ' + (time - (state.sessionRetrievalTime ?? 0)) + 'ms');
};

const setActualRenderTime = (time: number) =>
{
	const state = store.getState().performance;
	if (state.actualRenderTime !== null)
		return;

	store.dispatch(setActualRenderTimeRedux(time));
	//console.log('[Performance] Actual render time: ' + (time - (state.tabRetrievalTime ?? 0)) + 'ms');
};

export { 
	logPerformance, 
	setMountTime, 
	setStartConnectionTime, 
	setConnectionTime, 
	setSessionsFetchTime, 
	setSessionRetrievalTime, 
	setTabRetrievalTime, 
	setActualRenderTime 
};