import { setOverlay } from "@/redux/layoutSlice";
import store from "@/redux/store";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { useEffect, useLayoutEffect, useState } from "react";
import { Portal } from "@radix-ui/react-popover";

interface AdaptivePopoverProps {
	children: React.ReactNode;
	trigger: React.ReactNode;
	popoverTriggerProps?: React.ComponentProps<typeof PopoverTrigger>;
	popoverContentProps?: React.ComponentProps<typeof PopoverContent>;
	overlayContentProps?: React.ComponentProps<'div'>;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	dependencies?: any[];
}

const AdaptivePopover = ({children, trigger, popoverTriggerProps, popoverContentProps, overlayContentProps, open, onOpenChange, dependencies = []}: AdaptivePopoverProps) => {

	const [isOpen, setIsOpen] = useState(false);

	//console.log('AdaptivePopover render', dependencies);
	useLayoutEffect(() => {
		//console.log('AdaptivePopover deps updated, open?:', open);
		if(open && window.innerWidth < 1024)
		{
			console.log('Opening overlay');
			store.dispatch(setOverlay({
				content: <div {...overlayContentProps}>
					{children}
				</div>,
				closeCallback: () => onOpenChange && onOpenChange(false)
			}))
		}
		else
		{
			//console.log('children updated, but not open');
		}
	}, [...dependencies, open, children]);

	if(window.innerWidth < 1024) {
		return (
			<div onClick={() => {
				onOpenChange && onOpenChange(true);
				store.dispatch(setOverlay({
					content: <div 				
					{...overlayContentProps}
					onClick={(e) => {
						console.log('Overlay inner clicked, keeping open');
						e.stopPropagation();
						e.preventDefault();
					}}
					>
						{children}
					</div>,
					closeCallback: () => onOpenChange && onOpenChange(false)
				}));
			}}>
				{trigger}
			</div>
		)
	}

	return (
		<Popover open={(open === undefined ? isOpen : open)} onOpenChange={onOpenChange ?? setIsOpen}>
			<PopoverTrigger {...popoverTriggerProps} className="!m-0 !p-0">
				{trigger}
			</PopoverTrigger>
			<Portal>
				<PopoverContent {...popoverContentProps}>
					{children}
				</PopoverContent>
			</Portal>

		</Popover>
	)
}

export default AdaptivePopover;